<template>
  <div class="main_content">
    <el-card style="min-height: 400px">
      <div slot="header" class="clearfix">
        <span>搜索设置</span>
      </div>
      <el-row type="flex" justify="flex-start" :gutter="20">
        <el-col :span="6"
          ><div class="grid-content" @click="routerPush('/fbSearchSetting')">
            <h1 class="display_flex align_item_center">
              <i class="iconfont icon-Facebook"></i>
              设置Facebook搜索
            </h1>
            <p>设置Facebook网红的默认搜索条件</p>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content" @click="$router.push('/ttSearchSetting')">
            <h1 class="display_flex align_item_center">
              <i class="iconfont icon-icon_TikTok"></i>
              设置TikTok搜索
            </h1>
            <p>设置TikTok网红的默认搜索条件</p>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content" @click="$router.push('/ytSearchSetting')">
            <h1 class="display_flex align_item_center">
              <i class="iconfont icon-youtube1"></i>
              设置YouTube搜索
            </h1>
            <p>设置YouTube网红的默认搜索条件</p>
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content" @click="routerPush('/twitterSearchSetting')">
            <h1 class="display_flex align_item_center">
              <i class="iconfont icon-tuite"></i>
              设置Twitter搜索
            </h1>
            <p>设置Twitter网红的默认搜索条件</p>
          </div></el-col
        >
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    routerPush(url) {
      console.log(url);
      this.$router.push({
        path: url
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.grid-content {
  border-radius: 4px;
  border: 1px solid #d7dae2;
  padding: 20px;
  height: 100px;
  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    cursor: pointer;
  }
  h1 {
    // font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    .iconfont {
      font-size: 24px;
      margin-right: 10px;
    }
    .icon-Facebook {
      color: rgb(66, 95, 155);
    }
    .icon-youtube1 {
      color: rgb(255, 0, 0);
    }
    .icon-tuite {
      color: #1396db;
    }
  }
  >p {
    font-size: 14px;
    color: #909399;
    line-height: 1.5;
  }
}
</style>